import { render, staticRenderFns } from "./ShowRequestList.vue?vue&type=template&id=212cb1e0&"
import script from "./ShowRequestList.vue?vue&type=script&lang=js&"
export * from "./ShowRequestList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports