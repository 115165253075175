<template>
    <div>
        <b-col class="mb-3">
            <label><b>{{ $t('request_type') }}</b></label>
            <b-form-input readonly v-model="form.request_type_name">{{form.request_type_name??'-'}}</b-form-input>
        </b-col>

        <b-col  class="mb-3">
            <label><b>{{ $t('explanation') }}</b></label>
            <b-form-input readonly v-model="form.explanation"></b-form-input>
        </b-col>
        <b-col  class="mb-3">
            <ValidationObserver ref="formModalValidate">
                    <ValidationProvider name="status" rules="required" v-slot="{errors}">
                    <label><b>{{ $t('status') }}</b></label>
                        <parameter-selectbox
                            v-model="form.status"
                            :validate-error="errors[0]"
                            code="housing_request_statuses"

                        />
                     </ValidationProvider>
            </ValidationObserver>
        </b-col>
        <b-col cols="6" >
            <b-form-group :label="$t('file')">
                <b-input-group>
                    <b-form-input
                        :disabled="true"
                        v-model="form.file"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button variant="primary" @click="downloadFile" v-b-tooltip.hover title="Download Document" :disabled="!form.file">
                            <i class="ri-arrow-down-line"></i>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>
        <div class="d-flex justify-content-center mt-2" v-if="checkPermission('housingrequest_changestatus')">
            <b-button variant="primary" @click="updateStatus">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>
<script>
import HousingRequestListServices from "@/services/HousingRequestListServices";
import {EventBus} from "@/main";
export default {
    props:{
      formId:{}
    },
    data() {
        return {
            form:{
                request_type:null,
                status:null,
                files:null,
                explanation:null,
            }
        }
    },
    created() {
      this.getData()
    },
    methods: {
        downloadFile(){
            HousingRequestListServices.downloadFile(this.formData.file.uuid)
                .then(response => {
                    if (response.headers['content-type'] === 'application/pdf') {
                        let fileName = this.formData.file.file_name
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    } else {
                        this._downloadFile(response,this.formData.file.name)
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        },
       async getData(){
             HousingRequestListServices.showAdmin(this.formId)
                 .then(res => {
                     let data = res.data.data;
                     this.form={
                         request_type_name:data.request_type_name,
                         status:data.status,
                         files:data.files,
                         explanation:data.explanation??'-',
                     }
                 }).catch(error => {
                     this.showErrors(error)
             })
       },
        async updateStatus(){
            const isValid = await this.$refs.formModalValidate.validate()
            if (isValid){
                try {
                    const res = await HousingRequestListServices.changeStatusRequest(this.formId,this.form)
                    this.$toast.success(this.$t("api." + res.data.message));
                    this.$emit('successStatusUpdate', true)
                }catch(error){
                    if (error.response.status === 422){
                        this.$toast.success(this.$t("invalid_data"));
                    }
                }
            }
        }
    }
}
</script>
